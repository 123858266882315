@use 'global' as *;
$margin-bottom: 40px;

@mixin flex($media-query) {
  @include media($media-query) {
    flex-flow: wrap;
    flex-direction: row;
  }
}

// --------------------------------------------------------

@mixin column-props($width, $col-count) {
  > * {
    width: calc(#{$width} - ((#{$col-count} - 1) / #{$col-count} * var(--global-grid-gutter)));
  }
}

// -------------------------------------------------------- Grid container

.grid_container {
  align-items: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  width: 100%;

  &.padding_top {
    &_0 {
      padding-top: 0;
    }

    &_40 {
      padding-top: 20px;

      @include media($small-highest) {
        padding-top: 40px;
      }
    }

    &_80 {
      padding-top: 30px;

      @include media($small-highest) {
        padding-top: 80px;
      }
    }

    &_120 {
      padding-top: 50px;

      @include media($small-highest) {
        padding-top: 120px;
      }
    }
  }

  &.padding_bottom {
    &_0 {
      padding-bottom: 0;
    }

    &_40 {
      padding-bottom: 20px;

      @include media($small-highest) {
        padding-bottom: 40px;
      }
    }

    &_80 {
      padding-bottom: 30px;

      @include media($small-highest) {
        padding-bottom: 80px;
      }
    }

    &_120 {
      padding-bottom: 50px;

      @include media($small-highest) {
        padding-bottom: 120px;
      }
    }
  }
}

// -------------------------------------------------------- Grid content

.grid_content {
  display: flex;
  flex-direction: column;
  gap: var(--global-grid-gutter);
  height: auto;
  justify-content: flex-start;
  max-width: var(--global-max-width);
  width: 100%;
  z-index: var(--z-index-plus-two);

  img {
    height: auto;
  }
}

.header {
  margin-bottom: 4rem;
  max-width: $medium-lowest;
  text-align: center;
  text-wrap: balance;
  z-index: 1;
}

// -------------------------------------------------------- Grid columns

.column_1 {
  --grid-gutter: 0% !important;

  justify-content: center;

  @include column-props(100%, 1);

  &_narrow {
    justify-content: center;

    @include media($medium) {
      @include column-props($medium-lowest, 1);

      width: $medium-lowest;
    }
  }
}

.column_2 {
  @include flex($small-highest);

  @include media($small-highest) {
    @include column-props(50%, 2);
  }

  @include media($small-highest, down) {
    width: 100%;

    > * {
      width: 100%;
    }
  }

  // --------------------------------------------------------

  &.is_uneven {
    @include media($small-highest) {
      @include column-props(60%, 2);
    }

    > *:last-of-type {
      max-width: calc(40% - var(--global-grid-gutter));

      @include media($small-highest, down) {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &.reversed_on_mobile {
    @include media($small-highest, down) {
      > * {
        width: 100%;

        &:first-of-type {
          @include media($medium, down) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.column_3 {
  @include flex($small);

  @include media($small) {
    @include column-props(33.333%, 3);
  }

  &.reversed_on_mobile {
    @include media($small-highest, down) {
      > * {
        &:first-of-type {
          @include media($medium, down) {
            margin-bottom: 0;
          }
        }

        &:not(:first-of-type) {
          @include media($medium, down) {
            margin-bottom: $margin-bottom;
          }
        }
      }
    }
  }

  @include media($small) {
    ul {
      padding-left: 1rem;
    }
  }
}

.column_4 {
  @include flex($small-lowest);

  @include media($small-lowest) {
    @include column-props(50%, 2);
  }

  @include media($medium-highest) {
    @include column-props(25%, 4);
  }

  &.reversed_on_mobile {
    @include media($small-highest, down) {
      > * {
        &:first-of-type {
          @include media($medium, down) {
            margin-bottom: 0;
          }
        }

        &:not(:first-of-type) {
          @include media($medium, down) {
            margin-bottom: $margin-bottom;
          }
        }
      }
    }
  }

  @include media($small) {
    ul {
      padding-left: 1rem;
    }
  }
}

.reversed_on_mobile {
  @include media($small-highest, down) {
    flex-direction: column-reverse;
  }
}

// --------------------------------------------------------

.content_alignment {
  &_center {
    > * {
      @include media($small-lowest) {
        align-self: center;
      }
    }

    > div[class*='styles_is_rounded'] {
      @include media($small-lowest) {
        align-items: center;
        align-self: auto;
        display: flex;
      }
    }
  }

  &_bottom {
    > * {
      @include media($small-lowest) {
        align-self: flex-end;
      }
    }

    > div[class*='styles_is_rounded'] {
      @include media($small-lowest) {
        align-items: flex-end;
        align-self: auto;
        display: flex;
      }
    }
  }

  &_top {
    > * {
      @include media($small-lowest) {
        align-self: stretch;
      }
    }

    > div[class*='styles_is_rounded'] {
      @include media($small-lowest) {
        align-items: flex-start;
        align-self: auto;
        display: flex;
      }
    }
  }
}

.center_text {
  text-align: center;
}

// -------------------------------------------------------- | Background Angle

.background_angle {
  $small-angle: 100px;
  $medium-angle: 200px;
  $large-angle: 300px;

  position: relative;

  &::before {
    background-color: inherit;
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%);
    content: ' ';
    position: absolute;
    width: 100%;
    z-index: var(--z-index);
  }

  &_small {
    &::before {
      height: $small-angle;
      top: calc(-1 * $small-angle);
    }
  }

  &_medium {
    &::before {
      height: $medium-angle;
      top: calc((-1 * $medium-angle) + 1px);
    }
  }

  &_large {
    &::before {
      height: $large-angle;
      top: calc((-1 * $large-angle) + 1px);
    }
  }

  &_reversed {
    &::before {
      clip-path: polygon(0% 100%, 100% 100%, 100% 0%);
    }
  }
}

// --------------------------------------------------------

.is_rounded {
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-cloud);
  border-radius: 15px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  color: var(--color-off-black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: var(--global-max-width);
  padding: 1.5rem 1rem;
  width: 100%;
  z-index: var(--z-index-plus-one);

  p a {
    color: var(--color-lagoon);

    &:hover {
      color: var(--color-dusk);
    }
  }

  img,
  iframe {
    border-radius: 15px;
  }

  .header {
    margin-bottom: 20px;

    @include media($large) {
      margin: auto auto 40px;
    }
  }

  @include media($small-highest) {
    padding: 80px 40px;
  }
}

// --------------------------------------------------------

.width {
  &_medium {
    @include media($medium) {
      width: calc($medium - 2rem);
    }
  }

  &_narrow {
    width: 100%;

    @include media($small) {
      width: calc($small - 2rem);
    }
  }
}

// --------------------------------------------------------

.label {
  @include h6;

  text-align: center;
}

@use 'font' as *;
@use 'support' as *;

// ---------------------------------------------------------

@mixin h1-homepage {
  font-family: var(--font-family-headings);
  font-size: rfs(72px);
  font-weight: var(--font-weight-regular);
  line-height: rfs(80px);
}

@mixin h1 {
  font-family: var(--font-family-headings);
  font-size: rfs(48px);
  font-weight: var(--font-weight-regular);
  line-height: 1.2;
}

@mixin h2 {
  font-family: var(--font-family-headings);
  font-size: rfs(42px);
  font-weight: var(--font-weight-regular);
  line-height: 1.2;
}

@mixin h3 {
  font-family: var(--font-family-headings);
  font-size: rfs(36px);
  font-weight: var(--font-weight-regular);
  line-height: 1.3;
}

@mixin h4 {
  font-family: var(--font-family-base-bold);
  font-size: rfs(26px);
  font-weight: var(--font-weight-medium);
  line-height: 1.3;
}

@mixin h5 {
  font-family: var(--font-family-headings-bold);
  font-size: rfs(23px);
  font-weight: var(--font-weight-medium);
  line-height: 1.3;
}

@mixin h6 {
  font-family: var(--font-family-headings-bold);
  font-size: 0.75rem;
  font-weight: var(--font-weight-medium);
  letter-spacing: 2px;
  line-height: 2;
  text-transform: uppercase;
}

@mixin display {
  font-family: var(--font-family-headings);
  font-size: rfs(5.27rem);
  font-weight: var(--font-weight-regular);
  line-height: 1.1;
}

@mixin body {
  cursor: text;
  font-family: var(--font-family-base);
  line-height: 1.5;
}

// ---------------------------------------------------------

@mixin button {
  font-family: var(--font-family-headings-bold);
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

@mixin link {
  color: var(--color-lagoon);
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;

  @include hover {
    color: var(--color-dusk);
  }
}

// ---------------------------------------------------------

// ---------------------------------------------------------

@keyframes fade-out {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

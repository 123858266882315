@use 'global' as *;
.error_container {
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  height: 10vh;
  justify-content: center;
  text-align: center;
  width: 100%;
  z-index: var(--z-index-plus-one);
}
